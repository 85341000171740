import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { LoadUserResolver } from './load-user.resolver';
import { CanActivateGuard } from './msal/guards/canactivated.guard';

const routes: Routes = [
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: '', loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule), resolve: { user: LoadUserResolver }, canActivate: [CanActivateGuard,MsalGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
