/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiVendorMasterCurrencyActiveGet } from '../fn/vendor-master/api-vendor-master-currency-active-get';
import { ApiVendorMasterCurrencyActiveGet$Params } from '../fn/vendor-master/api-vendor-master-currency-active-get';
import { apiVendorMasterPortActiveGet } from '../fn/vendor-master/api-vendor-master-port-active-get';
import { ApiVendorMasterPortActiveGet$Params } from '../fn/vendor-master/api-vendor-master-port-active-get';
import { apiVendorMasterSimpleValueByMasterTypeGet } from '../fn/vendor-master/api-vendor-master-simple-value-by-master-type-get';
import { ApiVendorMasterSimpleValueByMasterTypeGet$Params } from '../fn/vendor-master/api-vendor-master-simple-value-by-master-type-get';
import { apiVendorMasterSimpleValueKeyValueByMasterTypeGet } from '../fn/vendor-master/api-vendor-master-simple-value-key-value-by-master-type-get';
import { ApiVendorMasterSimpleValueKeyValueByMasterTypeGet$Params } from '../fn/vendor-master/api-vendor-master-simple-value-key-value-by-master-type-get';
import { MasterCurrencyItem } from '../models/master-currency-item';
import { MasterSimpleKeyValueItem } from '../models/master-simple-key-value-item';
import { MasterSimpleValueItem } from '../models/master-simple-value-item';

@Injectable({ providedIn: 'root' })
export class VendorMasterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorMasterCurrencyActiveGet()` */
  static readonly ApiVendorMasterCurrencyActiveGetPath = '/api/vendor/master/Currency/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMasterCurrencyActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterCurrencyActiveGet$Response(params?: ApiVendorMasterCurrencyActiveGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MasterCurrencyItem>>> {
    return apiVendorMasterCurrencyActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMasterCurrencyActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterCurrencyActiveGet(params?: ApiVendorMasterCurrencyActiveGet$Params, context?: HttpContext): Observable<Array<MasterCurrencyItem>> {
    return this.apiVendorMasterCurrencyActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterCurrencyItem>>): Array<MasterCurrencyItem> => r.body)
    );
  }

  /** Path part for operation `apiVendorMasterPortActiveGet()` */
  static readonly ApiVendorMasterPortActiveGetPath = '/api/vendor/master/Port/Active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMasterPortActiveGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterPortActiveGet$Response(params?: ApiVendorMasterPortActiveGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return apiVendorMasterPortActiveGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMasterPortActiveGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterPortActiveGet(params?: ApiVendorMasterPortActiveGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiVendorMasterPortActiveGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `apiVendorMasterSimpleValueByMasterTypeGet()` */
  static readonly ApiVendorMasterSimpleValueByMasterTypeGetPath = '/api/vendor/master/SimpleValue/ByMasterType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMasterSimpleValueByMasterTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterSimpleValueByMasterTypeGet$Response(params?: ApiVendorMasterSimpleValueByMasterTypeGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MasterSimpleValueItem>>> {
    return apiVendorMasterSimpleValueByMasterTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMasterSimpleValueByMasterTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterSimpleValueByMasterTypeGet(params?: ApiVendorMasterSimpleValueByMasterTypeGet$Params, context?: HttpContext): Observable<Array<MasterSimpleValueItem>> {
    return this.apiVendorMasterSimpleValueByMasterTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterSimpleValueItem>>): Array<MasterSimpleValueItem> => r.body)
    );
  }

  /** Path part for operation `apiVendorMasterSimpleValueKeyValueByMasterTypeGet()` */
  static readonly ApiVendorMasterSimpleValueKeyValueByMasterTypeGetPath = '/api/vendor/master/SimpleValue/KeyValueByMasterType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorMasterSimpleValueKeyValueByMasterTypeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterSimpleValueKeyValueByMasterTypeGet$Response(params?: ApiVendorMasterSimpleValueKeyValueByMasterTypeGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MasterSimpleKeyValueItem>>> {
    return apiVendorMasterSimpleValueKeyValueByMasterTypeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorMasterSimpleValueKeyValueByMasterTypeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorMasterSimpleValueKeyValueByMasterTypeGet(params?: ApiVendorMasterSimpleValueKeyValueByMasterTypeGet$Params, context?: HttpContext): Observable<Array<MasterSimpleKeyValueItem>> {
    return this.apiVendorMasterSimpleValueKeyValueByMasterTypeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MasterSimpleKeyValueItem>>): Array<MasterSimpleKeyValueItem> => r.body)
    );
  }

}
