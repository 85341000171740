/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ApiServerTestTryConfigurationPost$Params {
  secret?: string;
  key?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiServerTestTryConfigurationPost(http: HttpClient, rootUrl: string, params?: ApiServerTestTryConfigurationPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, apiServerTestTryConfigurationPost.PATH, 'post');
  if (params) {
    rb.query('secret', params.secret, {});
    rb.query('key', params.key, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

apiServerTestTryConfigurationPost.PATH = '/api/server-test/try-configuration';
