/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AzureApiBaseService } from '../azure-api-base-service';
import { AzureApiConfiguration } from '../azure-api-configuration';
import { AzureApiStrictHttpResponse } from '../azure-api-strict-http-response';

import { apiVendorVendorAuctionGetVendorAttachmentGet } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-vendor-attachment-get';
import { ApiVendorVendorAuctionGetVendorAttachmentGet$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-vendor-attachment-get';
import { apiVendorVendorAuctionSubmitAllocatePost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-allocate-post';
import { ApiVendorVendorAuctionSubmitAllocatePost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-allocate-post';
import { apiVendorVendorAuctionSubmitDenyDealPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-deny-deal-post';
import { ApiVendorVendorAuctionSubmitDenyDealPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-deny-deal-post';
import { apiVendorVendorAuctionSubmitPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-post';
import { ApiVendorVendorAuctionSubmitPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-post';
import { apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-take-the-deal-price-to-win-post';
import { ApiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-take-the-deal-price-to-win-post';
import { apiVendorVendorAuctionUploadVendorAttactmentPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-upload-vendor-attactment-post';
import { ApiVendorVendorAuctionUploadVendorAttactmentPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-upload-vendor-attactment-post';
import { BooleanNullableResponseModel } from '../models/boolean-nullable-response-model';
import { DecimalNullableResponseModel } from '../models/decimal-nullable-response-model';
import { VendorAuctionDetailDtoResponseModel } from '../models/vendor-auction-detail-dto-response-model';
import { VendorAuctionWorksheetAttachmentDto } from '../models/vendor-auction-worksheet-attachment-dto';

@Injectable({ providedIn: 'root' })
export class VendorAuctionWorksheetAzureApiService extends AzureApiBaseService {
  constructor(config: AzureApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorVendorAuctionSubmitAllocatePost()` */
  static readonly ApiVendorVendorAuctionSubmitAllocatePostPath = '/api/vendor/vendor-auction/SubmitAllocate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSubmitAllocatePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitAllocatePost$Response(params?: ApiVendorVendorAuctionSubmitAllocatePost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<VendorAuctionDetailDtoResponseModel>> {
    return apiVendorVendorAuctionSubmitAllocatePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSubmitAllocatePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitAllocatePost(params?: ApiVendorVendorAuctionSubmitAllocatePost$Params, context?: HttpContext): Observable<VendorAuctionDetailDtoResponseModel> {
    return this.apiVendorVendorAuctionSubmitAllocatePost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<VendorAuctionDetailDtoResponseModel>): VendorAuctionDetailDtoResponseModel => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionSubmitPost()` */
  static readonly ApiVendorVendorAuctionSubmitPostPath = '/api/vendor/vendor-auction/Submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSubmitPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitPost$Response(params?: ApiVendorVendorAuctionSubmitPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<VendorAuctionDetailDtoResponseModel>> {
    return apiVendorVendorAuctionSubmitPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSubmitPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitPost(params?: ApiVendorVendorAuctionSubmitPost$Params, context?: HttpContext): Observable<VendorAuctionDetailDtoResponseModel> {
    return this.apiVendorVendorAuctionSubmitPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<VendorAuctionDetailDtoResponseModel>): VendorAuctionDetailDtoResponseModel => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost()` */
  static readonly ApiVendorVendorAuctionSubmitTakeTheDealPriceToWinPostPath = '/api/vendor/vendor-auction/SubmitTakeTheDealPriceToWin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Response(params?: ApiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<DecimalNullableResponseModel>> {
    return apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost(params?: ApiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Params, context?: HttpContext): Observable<DecimalNullableResponseModel> {
    return this.apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<DecimalNullableResponseModel>): DecimalNullableResponseModel => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionUploadVendorAttactmentPost()` */
  static readonly ApiVendorVendorAuctionUploadVendorAttactmentPostPath = '/api/vendor/vendor-auction/UploadVendorAttactment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionUploadVendorAttactmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionUploadVendorAttactmentPost$Response(params?: ApiVendorVendorAuctionUploadVendorAttactmentPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>> {
    return apiVendorVendorAuctionUploadVendorAttactmentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionUploadVendorAttactmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionUploadVendorAttactmentPost(params?: ApiVendorVendorAuctionUploadVendorAttactmentPost$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetAttachmentDto>> {
    return this.apiVendorVendorAuctionUploadVendorAttactmentPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>): Array<VendorAuctionWorksheetAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionGetVendorAttachmentGet()` */
  static readonly ApiVendorVendorAuctionGetVendorAttachmentGetPath = '/api/vendor/vendor-auction/GetVendorAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionGetVendorAttachmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetVendorAttachmentGet$Response(params?: ApiVendorVendorAuctionGetVendorAttachmentGet$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>> {
    return apiVendorVendorAuctionGetVendorAttachmentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionGetVendorAttachmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetVendorAttachmentGet(params?: ApiVendorVendorAuctionGetVendorAttachmentGet$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetAttachmentDto>> {
    return this.apiVendorVendorAuctionGetVendorAttachmentGet$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>): Array<VendorAuctionWorksheetAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionSubmitDenyDealPost()` */
  static readonly ApiVendorVendorAuctionSubmitDenyDealPostPath = '/api/vendor/vendor-auction/SubmitDenyDeal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSubmitDenyDealPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitDenyDealPost$Response(params?: ApiVendorVendorAuctionSubmitDenyDealPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<BooleanNullableResponseModel>> {
    return apiVendorVendorAuctionSubmitDenyDealPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSubmitDenyDealPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitDenyDealPost(params?: ApiVendorVendorAuctionSubmitDenyDealPost$Params, context?: HttpContext): Observable<BooleanNullableResponseModel> {
    return this.apiVendorVendorAuctionSubmitDenyDealPost$Response(params, context).pipe(
      map((r: AzureApiStrictHttpResponse<BooleanNullableResponseModel>): BooleanNullableResponseModel => r.body)
    );
  }

}
