import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalConfigs } from '../commons/global-config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, tap, catchError, concatMap } from 'rxjs/operators';
import { find, includes, isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AccountExRes } from '../api/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  me: AccountExRes;

  private subjectLoginDisplay = new BehaviorSubject<boolean>(false);
  private subjectAccountInfo = new BehaviorSubject<AccountExRes>(null);

  constructor() {
    this.subjectLoginDisplay.next(false);
  }

  setLoginDisplay(loginDisplay: boolean) {
    this.subjectLoginDisplay.next(loginDisplay);
  }

  getLoginDisplay() {
    return this.subjectLoginDisplay.asObservable();
  }

  setAccount(account: AccountExRes) {
    this.me = account;
    this.subjectAccountInfo.next(account);
  }

  getAccount() {
    return this.subjectAccountInfo.asObservable();
  }

  getAccessToken(): Promise<string | null> {
    const endpoint = find(GlobalConfigs.msal?.protectedResourceMaps, (res) => {
      return res.endpoint === window.location.protocol + "//" + window.location.host;
    });

    if (endpoint?.scopes) {
      const scope = endpoint.scopes[0];
      const accessTokenStore = find(Object.entries(localStorage), ([key, value]) => {
        return includes(key, scope);
      });
      const accessTokenObj = JSON.parse(accessTokenStore?.[1]);

      return accessTokenObj ? accessTokenObj.secret : null;
    }
  }

  get getCurrentAD(): string | null {
    return localStorage.getItem('currentAD');
  }
}
