/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiFileFilestreamPost } from '../fn/file-api/api-file-filestream-post';
import { ApiFileFilestreamPost$Params } from '../fn/file-api/api-file-filestream-post';
import { apiFilePost } from '../fn/file-api/api-file-post';
import { ApiFilePost$Params } from '../fn/file-api/api-file-post';
import { FileStreamUploadResp } from '../models/file-stream-upload-resp';
import { FileUploadResp } from '../models/file-upload-resp';

@Injectable({ providedIn: 'root' })
export class FileApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiFilePost()` */
  static readonly ApiFilePostPath = '/api/file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFilePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilePost$Response(params?: ApiFilePost$Params, context?: HttpContext): Observable<StrictHttpResponse<FileUploadResp>> {
    return apiFilePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFilePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFilePost(params?: ApiFilePost$Params, context?: HttpContext): Observable<FileUploadResp> {
    return this.apiFilePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileUploadResp>): FileUploadResp => r.body)
    );
  }

  /** Path part for operation `apiFileFilestreamPost()` */
  static readonly ApiFileFilestreamPostPath = '/api/file/filestream';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFileFilestreamPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileFilestreamPost$Response(params?: ApiFileFilestreamPost$Params, context?: HttpContext): Observable<StrictHttpResponse<FileStreamUploadResp>> {
    return apiFileFilestreamPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFileFilestreamPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFileFilestreamPost(params?: ApiFileFilestreamPost$Params, context?: HttpContext): Observable<FileStreamUploadResp> {
    return this.apiFileFilestreamPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileStreamUploadResp>): FileStreamUploadResp => r.body)
    );
  }

}
