import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, firstValueFrom, of } from 'rxjs';
import { AccountExRes } from './api/models';
import { AccountService } from './api/services';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class LoadUserResolver implements Resolve<Observable<AccountExRes>> {

  constructor(
    private accountService: AccountService,
    private authService: AuthService
  ) { }

  async resolve(): Promise<Observable<AccountExRes>> {
    let user = await firstValueFrom(this.accountService.apiAccountGet());
    this.authService.setAccount(user);
    return of(user);
  }
}
