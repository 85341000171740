/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AppService } from './services/app.service';
import { FileService } from './services/file.service';
import { FileApiService } from './services/file-api.service';
import { MasterSimpleValueService } from './services/master-simple-value.service';
import { ServerTestService } from './services/server-test.service';
import { SeverEventService } from './services/sever-event.service';
import { VendorActivityLogService } from './services/vendor-activity-log.service';
import { VendorAuctionWorksheetService } from './services/vendor-auction-worksheet.service';
import { VendorMasterService } from './services/vendor-master.service';
import { VendorRfqService } from './services/vendor-rfq.service';
import { VendorTaskListService } from './services/vendor-task-list.service';
import { WeatherForecastService } from './services/weather-forecast.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AppService,
    FileService,
    FileApiService,
    MasterSimpleValueService,
    ServerTestService,
    SeverEventService,
    VendorActivityLogService,
    VendorAuctionWorksheetService,
    VendorMasterService,
    VendorRfqService,
    VendorTaskListService,
    WeatherForecastService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
