import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import { GlobalConfigs, apiBaseUrlGetter } from './app/commons/global-config';
import { AppDataExRes } from './app/api/models';
import { isNil } from 'lodash';

if (environment.production) {
  enableProdMode();
}

//platformBrowserDynamic().bootstrapModule(AppModule)
//  .catch(err => console.error(err));

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

const baseUrl = apiBaseUrlGetter();

const currentAD = isNil(localStorage.getItem('currentAD')) ? '' : localStorage.getItem('currentAD');

fetch(baseUrl + "/api/app/data?config=" + currentAD).then(response => response.json()).then((res: AppDataExRes) => {

  console.log("Configs", res);

  Object.assign(GlobalConfigs.datas, res.conf);
  Object.assign(GlobalConfigs.msal, res.msal);

  if (environment.hmr) {
    if (module['hot']) {
      hmrBootstrap(module, bootstrap);
    } else {
      console.error('HMR is not enabled for webpack-dev-server!');
      console.log('Are you using the --hmr flag for ng serve?');
    }
  } else {
    bootstrap().catch(err => console.log(err));
  }

});
