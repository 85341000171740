/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AccountExRes } from '../models/account-ex-res';
import { apiAccountGet } from '../fn/account/api-account-get';
import { ApiAccountGet$Params } from '../fn/account/api-account-get';
import { apiAccountMeGet } from '../fn/account/api-account-me-get';
import { ApiAccountMeGet$Params } from '../fn/account/api-account-me-get';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountMeGet()` */
  static readonly ApiAccountMeGetPath = '/api/account/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountMeGet$Response(params?: ApiAccountMeGet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiAccountMeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountMeGet(params?: ApiAccountMeGet$Params, context?: HttpContext): Observable<string> {
    return this.apiAccountMeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `apiAccountGet()` */
  static readonly ApiAccountGetPath = '/api/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet$Response(params?: ApiAccountGet$Params, context?: HttpContext): Observable<StrictHttpResponse<AccountExRes>> {
    return apiAccountGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountGet(params?: ApiAccountGet$Params, context?: HttpContext): Observable<AccountExRes> {
    return this.apiAccountGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountExRes>): AccountExRes => r.body)
    );
  }

}
