/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { VendorAuctionWorksheetItemDto } from '../../models/vendor-auction-worksheet-item-dto';

export interface ApiVendorVendorAuctionImportItemPost$Params {

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
      body?: {
'File'?: Blob;
'AuctionId'?: string;
'VendorAucId'?: string;
}
}

export function apiVendorVendorAuctionImportItemPost(http: HttpClient, rootUrl: string, params?: ApiVendorVendorAuctionImportItemPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VendorAuctionWorksheetItemDto>>> {
  const rb = new RequestBuilder(rootUrl, apiVendorVendorAuctionImportItemPost.PATH, 'post');
  if (params) {
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<VendorAuctionWorksheetItemDto>>;
    })
  );
}

apiVendorVendorAuctionImportItemPost.PATH = '/api/vendor/vendor-auction/ImportItem';
