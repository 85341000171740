import { environment } from '../../environments/environment';
import { AzureApiConfiguration } from '../api-azure/azure-api-configuration';
import { ApiConfiguration } from '../api/api-configuration';
import { CliConfExResp, MsalConfigExRes } from '../api/models';

export class GlobalConfigs {
  public static datas: CliConfExResp = {};
  public static msal: MsalConfigExRes = {};
}

export function apiBaseUrlGetter() {

  if ((window as any).env) {
    console.log("apiBaseUrlGetter", {
      type: "(window as any).env.API_BASE_URL)",
      value: (window as any).env.API_BASE_URL
    });
    return (window as any).env.API_BASE_URL;
  }

  if (environment) {
    console.log("apiBaseUrlGetter", {
      type: "environment.base_url",
      value: environment.base_url
    });
    return environment.base_url;
  }

  const apiConfig: ApiConfiguration = new ApiConfiguration();
  console.log("apiBaseUrlGetter", {
    type: "apiConfig.rootUrl",
    value: apiConfig.rootUrl
  });
  return apiConfig.rootUrl;
}

export function apiAzureApiBaseUrlGetter() {

  if ((window as any).env) {
    console.log("apiAzureApiBaseUrlGetter", {
      type: "(window as any).env.API_AZURE_BASE_URL)",
      value: (window as any).env.API_AZURE_BASE_URL
    });
    return (window as any).env.API_AZURE_BASE_URL;
  }

  if (environment) {
    console.log("apiAzureApiBaseUrlGetter", {
      type: "environment.base_url_azure_api",
      value: environment.base_url_azure_api
    });
    return environment.base_url_azure_api;
  }

  const apiConfig: AzureApiConfiguration = new AzureApiConfiguration();
  console.log("apiAzureApiBaseUrlGetter", {
    type: "AzureApiConfiguration.rootUrl",
    value: apiConfig.rootUrl
  });
  return apiConfig.rootUrl;
}

export function apiAuctionBaseUrlGetter() {
  if ((window as any).env) {
    return (window as any).env.AUCTION_API_BASE_URL;
  } else {
    return apiAzureApiBaseUrlGetter();
  }
}
