/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MasterSimpleValueItem } from '../../models/master-simple-value-item';

export interface ApiVendorMasterSimpleValueByMasterTypeGet$Params {
  masterType?: string;
  workingGrorp?: string;

/**
 * User's Working Group
 */
  UserWorkingGroup?: String;
}

export function apiVendorMasterSimpleValueByMasterTypeGet(http: HttpClient, rootUrl: string, params?: ApiVendorMasterSimpleValueByMasterTypeGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MasterSimpleValueItem>>> {
  const rb = new RequestBuilder(rootUrl, apiVendorMasterSimpleValueByMasterTypeGet.PATH, 'get');
  if (params) {
    rb.query('masterType', params.masterType, {});
    rb.query('workingGrorp', params.workingGrorp, {});
    rb.header('UserWorkingGroup', params.UserWorkingGroup, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<MasterSimpleValueItem>>;
    })
  );
}

apiVendorMasterSimpleValueByMasterTypeGet.PATH = '/api/vendor/master/SimpleValue/ByMasterType';
