/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiVendorRfqCancelRfqDetailGet } from '../fn/vendor-rfq/api-vendor-rfq-cancel-rfq-detail-get';
import { ApiVendorRfqCancelRfqDetailGet$Params } from '../fn/vendor-rfq/api-vendor-rfq-cancel-rfq-detail-get';
import { apiVendorRfqCompleteRfqDetailPost } from '../fn/vendor-rfq/api-vendor-rfq-complete-rfq-detail-post';
import { ApiVendorRfqCompleteRfqDetailPost$Params } from '../fn/vendor-rfq/api-vendor-rfq-complete-rfq-detail-post';
import { apiVendorRfqItemGet } from '../fn/vendor-rfq/api-vendor-rfq-item-get';
import { ApiVendorRfqItemGet$Params } from '../fn/vendor-rfq/api-vendor-rfq-item-get';
import { apiVendorRfqSaveRfqDetailPost } from '../fn/vendor-rfq/api-vendor-rfq-save-rfq-detail-post';
import { ApiVendorRfqSaveRfqDetailPost$Params } from '../fn/vendor-rfq/api-vendor-rfq-save-rfq-detail-post';
import { RfqTransactionItem } from '../models/rfq-transaction-item';

@Injectable({ providedIn: 'root' })
export class VendorRfqService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorRfqItemGet()` */
  static readonly ApiVendorRfqItemGetPath = '/api/vendor/rfq/Item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorRfqItemGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorRfqItemGet$Response(params?: ApiVendorRfqItemGet$Params, context?: HttpContext): Observable<StrictHttpResponse<RfqTransactionItem>> {
    return apiVendorRfqItemGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorRfqItemGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorRfqItemGet(params?: ApiVendorRfqItemGet$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiVendorRfqItemGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiVendorRfqCancelRfqDetailGet()` */
  static readonly ApiVendorRfqCancelRfqDetailGetPath = '/api/vendor/rfq/CancelRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorRfqCancelRfqDetailGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorRfqCancelRfqDetailGet$Response(params?: ApiVendorRfqCancelRfqDetailGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return apiVendorRfqCancelRfqDetailGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorRfqCancelRfqDetailGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorRfqCancelRfqDetailGet(params?: ApiVendorRfqCancelRfqDetailGet$Params, context?: HttpContext): Observable<void> {
    return this.apiVendorRfqCancelRfqDetailGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiVendorRfqSaveRfqDetailPost()` */
  static readonly ApiVendorRfqSaveRfqDetailPostPath = '/api/vendor/rfq/SaveRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorRfqSaveRfqDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorRfqSaveRfqDetailPost$Response(params?: ApiVendorRfqSaveRfqDetailPost$Params, context?: HttpContext): Observable<StrictHttpResponse<RfqTransactionItem>> {
    return apiVendorRfqSaveRfqDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorRfqSaveRfqDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorRfqSaveRfqDetailPost(params?: ApiVendorRfqSaveRfqDetailPost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiVendorRfqSaveRfqDetailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

  /** Path part for operation `apiVendorRfqCompleteRfqDetailPost()` */
  static readonly ApiVendorRfqCompleteRfqDetailPostPath = '/api/vendor/rfq/CompleteRfqDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorRfqCompleteRfqDetailPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorRfqCompleteRfqDetailPost$Response(params?: ApiVendorRfqCompleteRfqDetailPost$Params, context?: HttpContext): Observable<StrictHttpResponse<RfqTransactionItem>> {
    return apiVendorRfqCompleteRfqDetailPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorRfqCompleteRfqDetailPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorRfqCompleteRfqDetailPost(params?: ApiVendorRfqCompleteRfqDetailPost$Params, context?: HttpContext): Observable<RfqTransactionItem> {
    return this.apiVendorRfqCompleteRfqDetailPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<RfqTransactionItem>): RfqTransactionItem => r.body)
    );
  }

}
