/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiVendorVendorAuctionCalBiddingPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-cal-bidding-post';
import { ApiVendorVendorAuctionCalBiddingPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-cal-bidding-post';
import { apiVendorVendorAuctionExportItemPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-export-item-post';
import { ApiVendorVendorAuctionExportItemPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-export-item-post';
import { apiVendorVendorAuctionGetBiddingPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-bidding-post';
import { ApiVendorVendorAuctionGetBiddingPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-bidding-post';
import { apiVendorVendorAuctionGetStatusVendorAllGet } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-status-vendor-all-get';
import { ApiVendorVendorAuctionGetStatusVendorAllGet$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-status-vendor-all-get';
import { apiVendorVendorAuctionGetVendorAttachmentGet } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-vendor-attachment-get';
import { ApiVendorVendorAuctionGetVendorAttachmentGet$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-vendor-attachment-get';
import { apiVendorVendorAuctionGetVendorAuctionGet } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-vendor-auction-get';
import { ApiVendorVendorAuctionGetVendorAuctionGet$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-get-vendor-auction-get';
import { apiVendorVendorAuctionImportItemPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-import-item-post';
import { ApiVendorVendorAuctionImportItemPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-import-item-post';
import { apiVendorVendorAuctionSaveBiddingPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-save-bidding-post';
import { ApiVendorVendorAuctionSaveBiddingPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-save-bidding-post';
import { apiVendorVendorAuctionSaveVendorItemPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-save-vendor-item-post';
import { ApiVendorVendorAuctionSaveVendorItemPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-save-vendor-item-post';
import { apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-take-the-deal-best-bid-to-win-post';
import { ApiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-submit-take-the-deal-best-bid-to-win-post';
import { apiVendorVendorAuctionUploadVendorAttactmentPost } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-upload-vendor-attactment-post';
import { ApiVendorVendorAuctionUploadVendorAttactmentPost$Params } from '../fn/vendor-auction-worksheet/api-vendor-vendor-auction-upload-vendor-attactment-post';
import { DecimalNullableResponseModel } from '../models/decimal-nullable-response-model';
import { ExportExcelFileResponse } from '../models/export-excel-file-response';
import { VendorAuctionDetailDto } from '../models/vendor-auction-detail-dto';
import { VendorAuctionDetailDtoResponseModel } from '../models/vendor-auction-detail-dto-response-model';
import { VendorAuctionWorksheetAttachmentDto } from '../models/vendor-auction-worksheet-attachment-dto';
import { VendorAuctionWorksheetItemDto } from '../models/vendor-auction-worksheet-item-dto';

@Injectable({ providedIn: 'root' })
export class VendorAuctionWorksheetService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorVendorAuctionCalBiddingPost()` */
  static readonly ApiVendorVendorAuctionCalBiddingPostPath = '/api/vendor/vendor-auction/CalBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionCalBiddingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionCalBiddingPost$Response(params?: ApiVendorVendorAuctionCalBiddingPost$Params, context?: HttpContext): Observable<StrictHttpResponse<VendorAuctionDetailDto>> {
    return apiVendorVendorAuctionCalBiddingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionCalBiddingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionCalBiddingPost(params?: ApiVendorVendorAuctionCalBiddingPost$Params, context?: HttpContext): Observable<VendorAuctionDetailDto> {
    return this.apiVendorVendorAuctionCalBiddingPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorAuctionDetailDto>): VendorAuctionDetailDto => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionGetVendorAuctionGet()` */
  static readonly ApiVendorVendorAuctionGetVendorAuctionGetPath = '/api/vendor/vendor-auction/GetVendorAuction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionGetVendorAuctionGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetVendorAuctionGet$Response(params?: ApiVendorVendorAuctionGetVendorAuctionGet$Params, context?: HttpContext): Observable<StrictHttpResponse<VendorAuctionDetailDtoResponseModel>> {
    return apiVendorVendorAuctionGetVendorAuctionGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionGetVendorAuctionGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetVendorAuctionGet(params?: ApiVendorVendorAuctionGetVendorAuctionGet$Params, context?: HttpContext): Observable<VendorAuctionDetailDtoResponseModel> {
    return this.apiVendorVendorAuctionGetVendorAuctionGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorAuctionDetailDtoResponseModel>): VendorAuctionDetailDtoResponseModel => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionGetBiddingPost()` */
  static readonly ApiVendorVendorAuctionGetBiddingPostPath = '/api/vendor/vendor-auction/GetBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionGetBiddingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionGetBiddingPost$Response(params?: ApiVendorVendorAuctionGetBiddingPost$Params, context?: HttpContext): Observable<StrictHttpResponse<VendorAuctionDetailDto>> {
    return apiVendorVendorAuctionGetBiddingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionGetBiddingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionGetBiddingPost(params?: ApiVendorVendorAuctionGetBiddingPost$Params, context?: HttpContext): Observable<VendorAuctionDetailDto> {
    return this.apiVendorVendorAuctionGetBiddingPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorAuctionDetailDto>): VendorAuctionDetailDto => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost()` */
  static readonly ApiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPostPath = '/api/vendor/vendor-auction/SubmitTakeTheDealBestBidToWin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost$Response(params?: ApiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost$Params, context?: HttpContext): Observable<StrictHttpResponse<DecimalNullableResponseModel>> {
    return apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost(params?: ApiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost$Params, context?: HttpContext): Observable<DecimalNullableResponseModel> {
    return this.apiVendorVendorAuctionSubmitTakeTheDealBestBidToWinPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<DecimalNullableResponseModel>): DecimalNullableResponseModel => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionSaveVendorItemPost()` */
  static readonly ApiVendorVendorAuctionSaveVendorItemPostPath = '/api/vendor/vendor-auction/SaveVendorItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSaveVendorItemPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSaveVendorItemPost$Response(params?: ApiVendorVendorAuctionSaveVendorItemPost$Params, context?: HttpContext): Observable<StrictHttpResponse<VendorAuctionWorksheetItemDto>> {
    return apiVendorVendorAuctionSaveVendorItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSaveVendorItemPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSaveVendorItemPost(params?: ApiVendorVendorAuctionSaveVendorItemPost$Params, context?: HttpContext): Observable<VendorAuctionWorksheetItemDto> {
    return this.apiVendorVendorAuctionSaveVendorItemPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorAuctionWorksheetItemDto>): VendorAuctionWorksheetItemDto => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionSaveBiddingPost()` */
  static readonly ApiVendorVendorAuctionSaveBiddingPostPath = '/api/vendor/vendor-auction/SaveBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionSaveBiddingPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSaveBiddingPost$Response(params?: ApiVendorVendorAuctionSaveBiddingPost$Params, context?: HttpContext): Observable<StrictHttpResponse<VendorAuctionDetailDto>> {
    return apiVendorVendorAuctionSaveBiddingPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionSaveBiddingPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionSaveBiddingPost(params?: ApiVendorVendorAuctionSaveBiddingPost$Params, context?: HttpContext): Observable<VendorAuctionDetailDto> {
    return this.apiVendorVendorAuctionSaveBiddingPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<VendorAuctionDetailDto>): VendorAuctionDetailDto => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionExportItemPost()` */
  static readonly ApiVendorVendorAuctionExportItemPostPath = '/api/vendor/vendor-auction/ExportItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionExportItemPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionExportItemPost$Response(params?: ApiVendorVendorAuctionExportItemPost$Params, context?: HttpContext): Observable<StrictHttpResponse<ExportExcelFileResponse>> {
    return apiVendorVendorAuctionExportItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionExportItemPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionExportItemPost(params?: ApiVendorVendorAuctionExportItemPost$Params, context?: HttpContext): Observable<ExportExcelFileResponse> {
    return this.apiVendorVendorAuctionExportItemPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExportExcelFileResponse>): ExportExcelFileResponse => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionImportItemPost()` */
  static readonly ApiVendorVendorAuctionImportItemPostPath = '/api/vendor/vendor-auction/ImportItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionImportItemPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVendorVendorAuctionImportItemPost$Response(params?: ApiVendorVendorAuctionImportItemPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VendorAuctionWorksheetItemDto>>> {
    return apiVendorVendorAuctionImportItemPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionImportItemPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiVendorVendorAuctionImportItemPost(params?: ApiVendorVendorAuctionImportItemPost$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetItemDto>> {
    return this.apiVendorVendorAuctionImportItemPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorAuctionWorksheetItemDto>>): Array<VendorAuctionWorksheetItemDto> => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionUploadVendorAttactmentPost()` */
  static readonly ApiVendorVendorAuctionUploadVendorAttactmentPostPath = '/api/vendor/vendor-auction/UploadVendorAttactment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionUploadVendorAttactmentPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionUploadVendorAttactmentPost$Response(params?: ApiVendorVendorAuctionUploadVendorAttactmentPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>> {
    return apiVendorVendorAuctionUploadVendorAttactmentPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionUploadVendorAttactmentPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorVendorAuctionUploadVendorAttactmentPost(params?: ApiVendorVendorAuctionUploadVendorAttactmentPost$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetAttachmentDto>> {
    return this.apiVendorVendorAuctionUploadVendorAttactmentPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>): Array<VendorAuctionWorksheetAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionGetVendorAttachmentGet()` */
  static readonly ApiVendorVendorAuctionGetVendorAttachmentGetPath = '/api/vendor/vendor-auction/GetVendorAttachment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionGetVendorAttachmentGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetVendorAttachmentGet$Response(params?: ApiVendorVendorAuctionGetVendorAttachmentGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>> {
    return apiVendorVendorAuctionGetVendorAttachmentGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionGetVendorAttachmentGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetVendorAttachmentGet(params?: ApiVendorVendorAuctionGetVendorAttachmentGet$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetAttachmentDto>> {
    return this.apiVendorVendorAuctionGetVendorAttachmentGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorAuctionWorksheetAttachmentDto>>): Array<VendorAuctionWorksheetAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `apiVendorVendorAuctionGetStatusVendorAllGet()` */
  static readonly ApiVendorVendorAuctionGetStatusVendorAllGetPath = '/api/vendor/vendor-auction/GetStatusVendorAll';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorVendorAuctionGetStatusVendorAllGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetStatusVendorAllGet$Response(params?: ApiVendorVendorAuctionGetStatusVendorAllGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return apiVendorVendorAuctionGetStatusVendorAllGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorVendorAuctionGetStatusVendorAllGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorVendorAuctionGetStatusVendorAllGet(params?: ApiVendorVendorAuctionGetStatusVendorAllGet$Params, context?: HttpContext): Observable<Array<string>> {
    return this.apiVendorVendorAuctionGetStatusVendorAllGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
