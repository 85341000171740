/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiAppDataGet } from '../fn/app/api-app-data-get';
import { ApiAppDataGet$Params } from '../fn/app/api-app-data-get';
import { apiAppTryConnectAzureB2CGet } from '../fn/app/api-app-try-connect-azure-b-2-c-get';
import { ApiAppTryConnectAzureB2CGet$Params } from '../fn/app/api-app-try-connect-azure-b-2-c-get';
import { AppDataExRes } from '../models/app-data-ex-res';

@Injectable({ providedIn: 'root' })
export class AppService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAppDataGet()` */
  static readonly ApiAppDataGetPath = '/api/app/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet$Response(params?: ApiAppDataGet$Params, context?: HttpContext): Observable<StrictHttpResponse<AppDataExRes>> {
    return apiAppDataGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppDataGet(params?: ApiAppDataGet$Params, context?: HttpContext): Observable<AppDataExRes> {
    return this.apiAppDataGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<AppDataExRes>): AppDataExRes => r.body)
    );
  }

  /** Path part for operation `apiAppTryConnectAzureB2CGet()` */
  static readonly ApiAppTryConnectAzureB2CGetPath = '/api/app/try-connect-azure-b2c';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAppTryConnectAzureB2CGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureB2CGet$Response(params?: ApiAppTryConnectAzureB2CGet$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return apiAppTryConnectAzureB2CGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAppTryConnectAzureB2CGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAppTryConnectAzureB2CGet(params?: ApiAppTryConnectAzureB2CGet$Params, context?: HttpContext): Observable<boolean> {
    return this.apiAppTryConnectAzureB2CGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
