/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AzureApiStrictHttpResponse } from '../../azure-api-strict-http-response';
import { AzureApiRequestBuilder } from '../../azure-api-request-builder';

import { DecimalNullableResponseModel } from '../../models/decimal-nullable-response-model';
import { VendorAuctionWorksheetTakeTheDealDto } from '../../models/vendor-auction-worksheet-take-the-deal-dto';

export interface ApiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Params {
      body?: VendorAuctionWorksheetTakeTheDealDto
}

export function apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost(http: HttpClient, rootUrl: string, params?: ApiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost$Params, context?: HttpContext): Observable<AzureApiStrictHttpResponse<DecimalNullableResponseModel>> {
  const rb = new AzureApiRequestBuilder(rootUrl, apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as AzureApiStrictHttpResponse<DecimalNullableResponseModel>;
    })
  );
}

apiVendorVendorAuctionSubmitTakeTheDealPriceToWinPost.PATH = '/api/vendor/vendor-auction/SubmitTakeTheDealPriceToWin';
