/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiServerEventServerTimeGet } from '../fn/sever-event/api-server-event-server-time-get';
import { ApiServerEventServerTimeGet$Params } from '../fn/sever-event/api-server-event-server-time-get';

@Injectable({ providedIn: 'root' })
export class SeverEventService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiServerEventServerTimeGet()` */
  static readonly ApiServerEventServerTimeGetPath = '/api/server-event/server-time';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiServerEventServerTimeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerEventServerTimeGet$Response(params?: ApiServerEventServerTimeGet$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return apiServerEventServerTimeGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiServerEventServerTimeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiServerEventServerTimeGet(params?: ApiServerEventServerTimeGet$Params, context?: HttpContext): Observable<string> {
    return this.apiServerEventServerTimeGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
