/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { apiVendorTasklistVendorAuctionWorksheetTaskListPost } from '../fn/vendor-task-list/api-vendor-tasklist-vendor-auction-worksheet-task-list-post';
import { ApiVendorTasklistVendorAuctionWorksheetTaskListPost$Params } from '../fn/vendor-task-list/api-vendor-tasklist-vendor-auction-worksheet-task-list-post';
import { apiVendorTasklistVendorRfqTaskListPost } from '../fn/vendor-task-list/api-vendor-tasklist-vendor-rfq-task-list-post';
import { ApiVendorTasklistVendorRfqTaskListPost$Params } from '../fn/vendor-task-list/api-vendor-tasklist-vendor-rfq-task-list-post';
import { VendorAuctionWorksheetTaskListResultItem } from '../models/vendor-auction-worksheet-task-list-result-item';
import { VendorRfqTaskListResultItem } from '../models/vendor-rfq-task-list-result-item';

@Injectable({ providedIn: 'root' })
export class VendorTaskListService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiVendorTasklistVendorRfqTaskListPost()` */
  static readonly ApiVendorTasklistVendorRfqTaskListPostPath = '/api/vendor/tasklist/VendorRfqTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorTasklistVendorRfqTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorTasklistVendorRfqTaskListPost$Response(params?: ApiVendorTasklistVendorRfqTaskListPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VendorRfqTaskListResultItem>>> {
    return apiVendorTasklistVendorRfqTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorTasklistVendorRfqTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorTasklistVendorRfqTaskListPost(params?: ApiVendorTasklistVendorRfqTaskListPost$Params, context?: HttpContext): Observable<Array<VendorRfqTaskListResultItem>> {
    return this.apiVendorTasklistVendorRfqTaskListPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorRfqTaskListResultItem>>): Array<VendorRfqTaskListResultItem> => r.body)
    );
  }

  /** Path part for operation `apiVendorTasklistVendorAuctionWorksheetTaskListPost()` */
  static readonly ApiVendorTasklistVendorAuctionWorksheetTaskListPostPath = '/api/vendor/tasklist/VendorAuctionWorksheetTaskList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorTasklistVendorAuctionWorksheetTaskListPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorTasklistVendorAuctionWorksheetTaskListPost$Response(params?: ApiVendorTasklistVendorAuctionWorksheetTaskListPost$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VendorAuctionWorksheetTaskListResultItem>>> {
    return apiVendorTasklistVendorAuctionWorksheetTaskListPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiVendorTasklistVendorAuctionWorksheetTaskListPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiVendorTasklistVendorAuctionWorksheetTaskListPost(params?: ApiVendorTasklistVendorAuctionWorksheetTaskListPost$Params, context?: HttpContext): Observable<Array<VendorAuctionWorksheetTaskListResultItem>> {
    return this.apiVendorTasklistVendorAuctionWorksheetTaskListPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VendorAuctionWorksheetTaskListResultItem>>): Array<VendorAuctionWorksheetTaskListResultItem> => r.body)
    );
  }

}
